import { useRef, useState } from 'react'
import ForwardingVideoGray from '@assets/video/Service Section_Forwarding_gray.mp4'
import CRMVideoGray from '@assets/video/Service Section_CRM_gray.mp4'
import AccountingVideoGray from '@assets/video/Service Section_Accounting_gray.mp4'
import OrderVideoGray from '@assets/video/Service Section_Orders_gray.mp4'
import WMSVideoGray from '@assets/video/Service Section_WMS_gray.mp4'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { FAQItem } from 'container/faq-item/faq-item.component'
import { useTranslation } from 'react-i18next'

const TabHero = () => {
    const [isTab, setIsTab] = useState('company_profile.forwarding')
    const { t } = useTranslation()

    const itemTab = [
        {
            id: 1,
            icon: 'icon-ForwardingIcon ',
            name: 'company_profile.forwarding',
        },
        {
            id: 2,
            icon: 'icon-OrdersIcon ',
            name: 'company_profile.orders',
        },
        {
            id: 3,
            icon: 'icon-WarehouseIcon ',
            name: 'company_profile.warehouse',
        },
        {
            id: 4,
            icon: 'icon-AccountingIcon ',
            name: 'company_profile.accounting',
        },
        {
            id: 5,
            icon: 'icon-CRMIcon ',
            name: 'company_profile.crm',
        },
    ]

    const dataItemTab = [
        {
            name: 'company_profile.forwarding',
            icon: 'icon-ForwardingIcon',
            title: 'company_profile.optimise_your_supply_chain',
            desc: 'company_profile.loglines_setup_manage',
            product: [
                'company_profile.shipment',
                'company_profile.booking',
                'company_profile.fleet_management',
                'company_profile.tarrif_and_rates',
                'company_profile.quotes',
            ],
            video: ForwardingVideoGray,
        },
        {
            name: 'company_profile.orders',
            icon: 'icon-OrdersIcon',
            title: 'company_profile.manage_stacks_of_data',
            desc: 'company_profile.save_update_records',
            product: [
                'company_profile.order',
                'company_profile.supplier_bookings',
                'company_profile.container_load_lists',
                'company_profile.container_load_plans',
                'company_profile.quotes',
            ],
            video: OrderVideoGray,
        },
        {
            name: 'company_profile.warehouse',
            icon: 'icon-WarehouseIcon',
            title: 'company_profile.digitise_inventory_needs',
            desc: 'company_profile.define_warehouse_sites',
            product: [
                'company_profile.inventory',
                'company_profile.orders',
                'company_profile.receipts',
                'company_profile.product',
                'company_profile.report',
            ],
            video: WMSVideoGray,
        },
        {
            name: 'company_profile.accounting',
            icon: 'icon-AccountingIcon',
            title: 'company_profile.manage_digital_finance_tools',
            desc: 'company_profile.reduce_human_errors',
            product: [
                'company_profile.general_ledger',
                'company_profile.charge_codes',
                'company_profile.credit_term',
                'company_profile.billing',
                'company_profile.period_management',
            ],
            video: AccountingVideoGray,
        },
        {
            name: 'company_profile.crm',
            icon: 'icon-CRMIcon',
            title: 'company_profile.connect_and_control',
            desc: 'company_profile.centralised_database',
            product: [
                'company_profile.opportunity',
                'company_profile.projects',
                'company_profile.inquiry',
                'company_profile.quotation',
                'company_profile.communication',
            ],
            video: CRMVideoGray,
        },
    ]

    const sectionRef = useRef<HTMLDivElement>(null)

    return (
        <div className="w-full flex justify-center themes-bg-brand-v1 pb-[40px] lg:px-[6.25rem] md:px-10 sm:px-10 mobile:px-5 ">
            <div
                ref={sectionRef}
                className="container themes-text-gray-v9 relative mt-[-70px] z-10 md:hidden sm:hidden lg:grid border-[1px] rounded-md"
            >
                <div className="grid grid-cols-5 tab-item ">
                    {itemTab.map((data, idx) => {
                        return (
                            <div
                                key={`data-tab-item-${idx}`}
                                onClick={() => {
                                    if (sectionRef.current) {
                                        sectionRef.current.scrollIntoView({
                                            behavior: 'smooth',
                                            inline: 'center',
                                            block: 'center',
                                        })
                                    }
                                    setIsTab(data.name)
                                }}
                                className={`transition-all duration-300 flex items-center text-[20px]
                                    ${isTab === data.name ? 'bg-white border-b-4 themes-border-brand-v5 ' : 'themes-bg-gray-v2 themes-text-gray-v6 border-b-4 themes-border-gray'}
                                    ${idx === 0 ? 'rounded-tl-sm' : idx === 4 && 'rounded-tr-sm'} 
                                    text-[20px] font-semibold p-5 cursor-pointer`}
                            >
                                <i
                                    className={`${data.icon} pr-2 font-normal ${isTab === data.name && 'themes-text-brand-v5'} tracking-[-1px]`}
                                />
                                {t(data.name)}
                            </div>
                        )
                    })}
                </div>
                <div className="bg-white grid grid-cols-2 gap-3 flex-wrap px-10 py-10 rounded-b-md">
                    {dataItemTab.map((data, idx) => {
                        return (
                            data.name === isTab && (
                                <div
                                    key={`item-data-tabs-${idx}`}
                                    className="col-span-2 grid grid-cols-2 gap-6"
                                >
                                    <div>
                                        <video
                                            src={data.video}
                                            className="h-auto w-full rounded-[10px]"
                                            preload="auto"
                                            autoPlay
                                            loop
                                            muted
                                            playsInline
                                        />
                                    </div>
                                    <div>
                                        <div className="pb-4">
                                            <div className="text-[24px] font-medium themes-text-gray-v9 leading-[32px] tracking-[-1px]">
                                                {t(data.title)}
                                            </div>
                                            <div className="text-[18px] themes-text-gray-v6 leading-[20px] ">
                                                {t(data.desc)}
                                            </div>
                                        </div>
                                        <div className="text-[18px] border-l themes-border-brand-v6 themes-text-brand-v6 max-h-fit flex flex-col flex-wrap mt-2 pl-5">
                                            {data.product.map((item, idx) => (
                                                <div
                                                    className="pb-3"
                                                    key={`item=product-tab-${idx} `}
                                                >
                                                    {t(item)}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    })}
                </div>
            </div>
            <div className="container themes-text-gray-v7 md:grid sm:grid lg:hidden mt-5 lg:px-5">
                {dataItemTab.map((data, idx) => {
                    return (
                        <div key={`item-data-tabs-${idx}`}>
                            <FAQItem
                                icon={data.icon}
                                className="bg-white drop-shadow-2xl my-2 rounded-md"
                                title={t(data.title)}
                            >
                                <div className="col-span-2 grid grid-cols-1 bg-[#E1E9F5] p-5">
                                    <div className="flex justify-center">
                                        <div className="bg-[#C3D1E6] p-3 rounded-md">
                                            <video
                                                src={data.video}
                                                className="h-auto rounded-[10px]"
                                                preload="auto"
                                                autoPlay
                                                // loop
                                                muted
                                                playsInline
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-8">
                                        <div>
                                            <div className="text-[20px] font-semibold">
                                                {t(data.title)}
                                            </div>
                                            <div className="text-[18px] themes-text-gray-v7 py-4">
                                                {t(data.desc)}
                                            </div>
                                        </div>
                                        <div className="text-[18px] themes-text-gray-v7 flex flex-col">
                                            {data.product.map((item, idx) => (
                                                <div
                                                    key={`item=product-tab-${idx}`}
                                                    className="my-1"
                                                >
                                                    {t(item)}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="flex pt-4">
                                            <a
                                                href={endpoints.book}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="relative rounded p-4 px-4 py-2 overflow-hidden group themes-bg-brand-v5 hover:bg-gradient-to-r themes-bg-hover-brand-v6 themes-text-brand-v1 hover:ring-2 hover:ring-offset-2 transition-all ease-out duration-300"
                                            >
                                                <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 themes-bg-brand-v5 opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                                                <span className="relative">
                                                    {t(
                                                        'company_profile.book_a_demo',
                                                    )}
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </FAQItem>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TabHero

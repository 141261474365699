import { IData } from './main-page.interface'
import WorkflowVideo from '@assets/video/OMS_whitebg.mov'
import BusinessVideo from '@assets/video/BI_whitebg.mov'
import ShipmentVideo from '@assets/video/Shipment_whitebg.mov'
import UserManagementVideo from '@assets/video/User management_whitebg.mov'
import RatesVideo from '@assets/video/Rates_whitebg.mov'
import AdditionalVideo from '@assets/video/Additional Features_whitebg.mov'
import ImageJohnstone from '@assets/img/gallery/johnstone.png'
import ImageLowes from '@assets/img/gallery/lowes.png'
import ImageTrancy from '@assets/img/gallery/trancy.png'

export const logHelpData: IData[] = [
    {
        video: WorkflowVideo,
        title: 'company_profile.workflow',
        desc: 'company_profile.workflow_description',
    },
    {
        video: BusinessVideo,
        title: 'company_profile.business_intelligence_analytics',
        desc: 'company_profile.business_intelligence_analytics_description',
    },
    {
        video: ShipmentVideo,
        title: 'company_profile.shipment_tracking',
        desc: 'company_profile.shipment_tracking_description',
    },
    {
        video: UserManagementVideo,
        title: 'company_profile.user_management_rbac',
        desc: 'company_profile.user_management_rbac_description',
    },
    {
        video: RatesVideo,
        title: 'company_profile.rates_management',
        desc: 'company_profile.rates_management_description',
    },
    {
        video: AdditionalVideo,
        title: 'company_profile.additional_features',
        desc: 'company_profile.additional_features_description',
    },
]

export const platformData: IData[] = [
    {
        title: 'company_profile.security',
        desc: 'company_profile.security_description',
    },
    {
        title: 'company_profile.scalability',
        desc: 'company_profile.scalability_description',
    },
    {
        title: 'company_profile.time_to_value',
        desc: 'company_profile.time_to_value_description',
    },
    {
        title: 'company_profile.integration',
        desc: 'company_profile.integration_description',
    },
    {
        title: 'company_profile.user_centric',
        desc: 'company_profile.user_centric_description',
    },
    {
        title: 'company_profile.efficiency',
        desc: 'company_profile.efficiency_description',
    },
]

export const industryData: IData[] = [
    {
        icon: 'icon-ManufacturerIcon',
        title: 'company_profile.manufacturer',
        desc: 'company_profile.manufacturer_description',
    },
    {
        icon: 'icon-FoodandBeverageIcon',
        title: 'company_profile.food_beverage',
        desc: 'company_profile.food_beverage_description',
    },
    {
        icon: 'icon-PharmaceuticalIcon',
        title: 'company_profile.pharmaceutical',
        desc: 'company_profile.pharmaceutical_description',
    },
    {
        icon: 'icon-AutomotiveIcon',
        title: 'company_profile.automotive',
        desc: 'company_profile.automotive_description',
    },
    {
        icon: 'icon-RetailIcon',
        title: 'company_profile.retail',
        desc: 'company_profile.retail_description',
    },
    {
        icon: 'icon-ContructionSuppliesIcon',
        title: 'company_profile.construction_supplies',
        desc: 'company_profile.construction_supplies_description',
    },
    {
        icon: 'icon-ElectronicsIcon',
        title: 'company_profile.electronics',
        desc: 'company_profile.electronics_description',
    },
    {
        icon: 'icon-E-commerceCompaniesIcon',
        title: 'company_profile.ecommerce_companies',
        desc: 'company_profile.ecommerce_companies_description',
    },
    {
        icon: 'icon-OtherIndustriesIcon',
        title: 'company_profile.other_industries',
        desc: 'company_profile.adaptable_logistics',
    },
]

export const companiesData: IData[] = [
    {
        img: ImageLowes,
        title: `company_profile.technology_guidance_quote`,
        desc: 'company_profile.lowe_network',
    },
    {
        img: ImageJohnstone,
        title: `company_profile.onebyone_wms_quote`,
        desc: 'company_profile.johnstone_supply',
    },
    {
        img: ImageTrancy,
        title: `company_profile.trancy_japan_quote`,
        desc: 'company_profile.trancy_japan_case_study',
    },
]

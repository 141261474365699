const useService = () => {
    let playTimeout: string | number | NodeJS.Timeout | undefined
    const start = async (e: React.MouseEvent<HTMLVideoElement>) => {
        let video = e.target as HTMLVideoElement
        playTimeout = setTimeout(() => {
            video.loop = true
            if (video.paused) video.play()
        }, 300)
    }

    const off = async (e: React.MouseEvent<HTMLVideoElement>) => {
        let video = e.target as HTMLVideoElement
        if (playTimeout) {
            clearTimeout(playTimeout)
        }
        video.loop = false
    }

    const loadMeta = (e: React.SyntheticEvent<HTMLVideoElement>) => {
        let video = e.target as HTMLVideoElement
        video.currentTime = video.duration
        video.playbackRate = 2
    }
    return {
        start,
        loadMeta,
        off,
    }
}

export default useService

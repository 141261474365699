import BgImageHero from '@assets/img/gallery/hero_background.webp'
import VideoPlaceholder from '@assets/img/gallery/hero_placeholder.webp'
import ContainerVideo from '@assets/video/Services Section_Hero Video_UPDATE.mp4'
import Arrow from '../../assets/images/arrow.png'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import Header from 'layout/header.component'
import { useTranslation } from 'react-i18next'

const HeroContainer = () => {
    const { t } = useTranslation()
    return (
        <div className="relative min-h-screen ">
            <Header />
            <div
                className="absolute inset-0 w-full h-full lg:bg-[length:100%_110%] bg-[length:240%_120%] sm:bg-[length:320%_150%] brightness-150 bg-no-repeat "
                style={{
                    backgroundImage: `url(${BgImageHero})`,
                    backgroundPosition: 'left 25% top 30%',
                }}
            ></div>
            <div className="absolute inset-0 w-full h-full bg-black opacity-50"></div>
            <div className="relative w-full flex justify-center items-center min-h-screen lg:py-10 lg:px-[6.25rem] md:px-10 sm:px-10 mobile:px-5 ">
                <div className=" lg:grid md:hidden sm:hidden gap-4 grid-cols-2 justify-between items-center container pb-10 lg:pt-5">
                    <div
                        data-aos="fade-right"
                        className="themes-text-brand-v1 w-full max-w-[700px] hero"
                    >
                        <h1 className="text-[64px] mt-[20px] inter leading-[77.45px] max-w-full tracking-[-5px] font-extralight">
                            {t('company_profile.digitise_logistics')},<br />{' '}
                            {t('company_profile.connect_supply_chain')}
                        </h1>
                        <p className="text-[18px] mb-[20px] inter max-w-full tracking-[-1px] font-normal">
                            {t('company_profile.run_your_business')}
                        </p>
                        <a
                            href={endpoints.book}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="relative inline-flex items-center justify-center p-4 px-4 py-2 themes-bg-brand-v5 themes-text-brand-v1 overflow-hidden transition duration-300 ease-out rounded-md shadow-md group"
                        >
                            <span className="absolute themes-bg-brand-v6 inset-0 flex items-center justify-center w-full h-full themes-text-brand-v1 duration-300 -translate-x-full group-hover:translate-x-0 ease">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                                    />
                                </svg>
                            </span>
                            <span className="px-5 absolute font-bold flex items-center justify-center w-full h-full themes-text-brand-v1 transition-all duration-300 transform group-hover:translate-x-full ease">
                                {t('company_profile.get_started')}
                                {/* <i className="ri-arrow-right-line themes-text-brand-v7 bg-white py-[1px] px-[5px] ml-2 text-black rounded-full" /> */}
                            </span>
                            <span className="px-5 relative invisible">
                                {t('company_profile.get_started')}
                                {/* <i className="ri-arrow-right-line themes-text-brand-v6 bg-white py-[1px] px-[5px] text-black rounded-full" /> */}
                            </span>
                        </a>
                    </div>
                    <div className="relative flex flex-col text-size-L text-white items-center">
                        <div
                            data-aos="fade-right"
                            className="absolute flex flex-row w-full justify-end mr-5 z-50 hero"
                        >
                            <div className="relative w-fit top-[-30px] left-0 flex ">
                                <span className="mb-2 lg:text-nowrap tracking-[-1px]">
                                    {t('company_profile.simplify_workflow')}
                                </span>
                                <img
                                    className="transform rotate-180 h-[70px]"
                                    src={Arrow}
                                    alt="Arrow"
                                />
                            </div>
                        </div>
                        <div
                            data-aos="zoom-in"
                            className="z-0 rounded-[21px] themes-bg-gray-v7 p-[0.4px] xl:p-[1px] hero"
                        >
                            <div className="rounded-[21px] bg-[#222222] p-[1rem]">
                                <video
                                    poster={VideoPlaceholder}
                                    src={ContainerVideo}
                                    preload="metadata"
                                    className="h-auto w-full rounded-[10px]"
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                />
                            </div>
                        </div>
                        <div
                            data-aos="fade-left"
                            className="absolute flex flex-row bottom-[-14px]  w-full  justify-start ml-5 hero"
                        >
                            <div className="relative  w-fit top-[30px] flex flex-row">
                                <img
                                    className=" h-[70px]"
                                    src={Arrow}
                                    alt="Arrow"
                                />
                                <span className="mt-8 lg:text-nowrap tracking-[-1px]">
                                    {t('company_profile.easily_connect')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:grid pt-[100px] lg:hidden grid-cols-1 justify-between items-center container">
                    <div className="relative flex flex-col text-size-L text-white ">
                        <div
                            data-aos="fade-right"
                            className="absolute flex flex-row w-full z-50 hero"
                        >
                            <div className="relative w-full top-[-30px] mobile:top-[-22px] flex justify-end mr-5">
                                <span className="text-wrap lg:text-nowrap leading-[20px] tracking-[-1px] mobile:text-[18px] ">
                                    {t('company_profile.simplify_workflow')}
                                </span>
                                <img
                                    className="transform rotate-180 w-[40px]"
                                    src={Arrow}
                                    alt="Arrow"
                                />
                            </div>
                        </div>
                        <div
                            data-aos="zoom-in"
                            className=" mobile:mt-5 mobile:mb-5 rounded-[21px] bg-white py-[0.8px] px-[0.8px] z-0 hero"
                        >
                            <div className="rounded-[21px] bg-black p-2">
                                <video
                                    poster={VideoPlaceholder}
                                    src={ContainerVideo}
                                    preload="metadata"
                                    className="h-auto w-full rounded-[10px]"
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                />
                            </div>
                        </div>
                        <div
                            data-aos="fade-left"
                            className="absolute flex flex-row bottom-[-14px] w-full justify-start ml-5 hero"
                        >
                            <div className="relative w-fit top-[20px] flex flex-row">
                                <img
                                    className=" w-[40px]"
                                    src={Arrow}
                                    alt="Arrow"
                                />
                                <span className="lg:text-nowrap mt-8 text-wrap leading-[20px] mobile:text-[18px] tracking-[-1px]">
                                    {t('company_profile.easily_connect')}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="themes-text-brand-v1 w-full max-w-[500px] pb-5">
                        <h1 className="text-[42px] mt-14 mb-4 inter leading-[50px] font-extralight">
                            {t('company_profile.digitise_logistics')},<br />{' '}
                            {t('company_profile.connect_supply_chain')}
                        </h1>
                        <p className="text-[18px] mb-4 inter leading-[20px] font-normal tracking-[-1px]">
                            {t('company_profile.run_your_business')}
                        </p>
                        <a
                            href={endpoints.book}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="relative inline-flex items-center justify-center p-4 px-4 py-2 themes-bg-brand-v5 themes-text-brand-v1 overflow-hidden transition duration-300 ease-out rounded-md shadow-md group"
                        >
                            <span className="absolute themes-bg-brand-v6 inset-0 flex items-center justify-center w-full h-full themes-text-brand-v1 duration-300 -translate-x-full group-hover:translate-x-0 ease">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                                    />
                                </svg>
                            </span>
                            <span className="px-5 absolute font-bold flex items-center justify-center w-full h-full themes-text-brand-v1 transition-all duration-300 transform group-hover:translate-x-full ease">
                                {t('company_profile.get_started')}
                                {/* <i className="ri-arrow-right-line ml-2" /> */}
                            </span>
                            <span className="px-5 relative invisible">
                                {t('company_profile.get_started')}
                                {/* <i className="ri-arrow-right-line" /> */}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroContainer

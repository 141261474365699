import Ship from '@assets/img/gallery/ship_image.webp'
import {
    companiesData,
    industryData,
    logHelpData,
    platformData,
} from './main-page.static'
import './main-page.style.css'
import PlatformContainer from 'container/platform/platform.component'
import HeroContainer from 'container/hero/hero.container'
import TabHero from 'container/tab-hero/tab-hero.component'
import SliderContainer from 'container/slider/slider.component'
import { FAQItem } from 'container/faq-item/faq-item.component'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { useTranslation } from 'react-i18next'
import useService from './main-page.service'

const MainPage = () => {
    const { t } = useTranslation()
    const { start, loadMeta, off } = useService()
    return (
        <>
            {/* Hero section */}
            <HeroContainer />
            {/* Tab hero section */}
            <TabHero />
            {/* Platform section */}
            <PlatformContainer data={platformData} />

            {/* How Loglines helps / Workflow section */}
            <div className="w-full flex justify-center bg-white pt-[90px] sm:pt-[60px] pb-[90px] lg:px-[6.25rem] md:px-10 sm:px-10 mobile:px-5 ">
                <div className="container  leading-normal">
                    <div
                        data-aos="fade-up"
                        className="text-[48px] themes-text-gray-v9 inter leading-tight"
                    >
                        {t('company_profile.how_loglines_can_help')}
                    </div>
                    <div
                        data-aos="fade-up"
                        className="text-[28px] themes-text-gray-v6 mb-[80px] leading-tight"
                    >
                        {t('company_profile.streamline_operations')}
                    </div>
                    <div className="grid gap-20 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                        {logHelpData.map((data, idx) => {
                            return (
                                <div
                                    data-aos="fade-up"
                                    key={`platform-data-${idx}`}
                                >
                                    <video
                                        key={`vid-${idx}`}
                                        src={data.video}
                                        className="h-auto w-full rounded-[10px]"
                                        preload="metadata"
                                        autoPlay
                                        onLoadedMetadata={loadMeta}
                                        onMouseEnter={start}
                                        onMouseOver={start}
                                        onMouseLeave={off}
                                        onMouseOut={off}
                                        muted
                                        playsInline
                                    />
                                    <div className="mt-5">
                                        <div className="font-medium text-[24px] themes-text-gray-v9">
                                            {t(data.title)}
                                        </div>
                                        <div className="mt-2 text-[18px] leading-[20px] themes-text-gray-v6">
                                            {t(data.desc)}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {/* What Industry does Loglines cover section */}
            <div className="relative h-full themes-bg-gray-v1">
                <div className="relative w-full flex justify-center py-10 lg:px-[6.25rem] md:px-10 sm:px-10 mobile:px-5 ">
                    <div className="container themes-text-gray-v7 ">
                        <div className="grid gap-10 lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2">
                            {industryData.map((data, idx) => {
                                return (
                                    <div
                                        data-aos="fade-up"
                                        key={`platform-data-${idx}`}
                                        className="relative h-fit sm:h-fit lg:w-[285px] xl:w-[360px] 2xl:w-[450px] flex flex-row text-wrap mb-2 tracking-[-1px]"
                                    >
                                        <div className="text-[27px] mt-2 ">
                                            <i className={`${data.icon}`} />
                                        </div>
                                        <div className="leading-[20px] ml-2.5 mobile:text-[15px] text-[18px]">
                                            <span className="font-bold ">
                                                {`${t(data.title)} : `}
                                            </span>
                                            {/* <span className="mobile:block"> */}
                                            {t(data.desc)}
                                            {/* </span> */}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {/* Other companeis said section */}
            <SliderContainer data={companiesData} />
            {/* FAQ section */}
            <div className="w-full flex justify-center flex-col items-center bg-white py-[80px] lg:px-[6.25rem] md:px-10 sm:px-10 mobile:px-5 ">
                <div className="container themes-text-gray-v7 ">
                    <div
                        data-aos="fade-up"
                        className="text-[48px] inter leading-[40px] tracking-[-3px] mb-2"
                    >
                        {t('company_profile.common_question')}
                    </div>
                    <div
                        data-aos="fade-up"
                        className="text-[28px] inter mb-[30px] leading-[24px]"
                    >
                        {t('company_profile.customer_support_prompt')}{' '}
                        <a
                            href="mailto:team@onebyone.io"
                            className="themes-text-brand-v5 font-medium"
                        >
                            {t('company_profile.customer_support_team')}.
                        </a>
                    </div>
                    <div
                        data-aos="fade-up"
                        className=" bg-white rounded-md  drop-shadow-md"
                    >
                        <FAQItem title={t('company_profile.how_to_subscribe')}>
                            <div className="px-[30px] pb-[15px]">
                                {t('company_profile.access_subscribe_page')}{' '}
                                <a
                                    href="mailto:team@onebyone.io"
                                    className="themes-text-brand-v5 font-medium"
                                >
                                    {t('company_profile.subscribe_page')}
                                </a>
                                <br />
                                {t('company_profile.monthly_fee_description')}
                            </div>
                        </FAQItem>
                        <FAQItem
                            title={t(
                                'company_profile.cancel_subscription_effects',
                            )}
                        >
                            <div className="px-[30px] pb-[15px]">
                                {t('company_profile.access_subscribe_page')}
                                {t(
                                    'company_profile.cancel_subscription_info',
                                )}{' '}
                                {t(
                                    'company_profile.cancel_subscription_30_days',
                                )}
                                <br />
                                {t(
                                    'company_profile.access_modules_description',
                                )}
                            </div>
                        </FAQItem>
                        <FAQItem
                            title={t(
                                'company_profile.post_cancel_support_access',
                            )}
                        >
                            <div className="px-[30px] pb-[15px]">
                                {t('company_profile.resubscribe_for_support')}{' '}
                            </div>
                        </FAQItem>
                        <FAQItem title={t('company_profile.gdpr_compliance')}>
                            <div className="px-[30px] pb-[15px]">
                                {t('company_profile.privacy_policy_info')}{' '}
                                <a
                                    href="https://www.onebyone.io/privacy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="themes-text-brand-v5 font-medium"
                                >
                                    {t('company_profile.privacy_policy')}
                                </a>
                                .
                            </div>
                        </FAQItem>
                    </div>
                </div>
            </div>
            {/* Unleash potential section */}
            <div className="relative h-96 !bg-white">
                <img
                    src={Ship}
                    alt="bg-hero"
                    className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="relative z-10 flex items-center h-full justify-center lg:px-[6.25rem] md:px-10 sm:px-10 mobile:px-5 ">
                    <div className="container ">
                        <div
                            data-aos="fade-up"
                            className="mobile:text-[36px] text-[40px] md:text-[48px] themes-text-gray-v1 inter pb-2 tracking-[-3px]"
                        >
                            {t('company_profile.unleash_potential')}
                        </div>
                        <div
                            data-aos="fade-up"
                            className="mobile:text-[18px] text-[20px] md:text-[28px] themes-text-gray-v1 font-light leading-8 tracking-[-1px]"
                        >
                            {t('company_profile.see_erp_power')}
                            <br />
                            {t('company_profile.get_started_now')}
                        </div>
                    </div>
                </div>
            </div>
            {/* footer section */}
            <div className="w-full flex justify-center bg-[#F0FAFE] pt-[90px] sm:pt-[60px] lg:px-[6.25rem] md:px-10 sm:px-10 mobile:px-5  ">
                <div className="container themes-text-gray-v9 ">
                    <div className="flex justify-between sm:flex-col">
                        <div>
                            <i
                                className={`icon-Logos_LogLines text-[80px] themes-text-brand-v5`}
                            />
                            <div>{t('company_profile.digital_precision')}</div>
                        </div>
                        <div className="flex gap-3 sm:flex-col ">
                            <div className="flex flex-col mr-10 ">
                                <span className="text-[26px] themes-text-brand-v5 sm:mt-5">
                                    OneByOne
                                </span>
                                <a
                                    href={
                                        'https://www.onebyone.io/software-engineering'
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn-line-animasi themes-text-gray-v9 py-1"
                                >
                                    {t('company_profile.engineering')}
                                </a>
                                <a
                                    href={
                                        'https://www.onebyone.io/systems-integration'
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn-line-animasi themes-text-gray-v9 py-1"
                                >
                                    {t('company_profile.integration')}
                                </a>
                                <a
                                    href={'https://www.onebyone.io/transport'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn-line-animasi themes-text-gray-v9 py-1"
                                >
                                    {t('company_profile.transport')}
                                </a>
                                <a
                                    href={'https://www.onebyone.io/warehousing'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn-line-animasi themes-text-gray-v9 py-1"
                                >
                                    {t('company_profile.inventory')}
                                </a>
                                <a
                                    href={'https://www.onebyone.io/about'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn-line-animasi themes-text-gray-v9 py-1"
                                >
                                    {t('company_profile.about')}
                                </a>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-[26px] themes-text-brand-v5 sm:mt-5">
                                    {t('company_profile.support')}
                                </span>
                                <a
                                    href="mailto:team@onebyone.io"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn-line-animasi themes-text-gray-v9 py-1"
                                >
                                    {t('company_profile.email_us')}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center mt-10 py-10">
                        <div className="text-[16px] themes-text-gray-v9 ">
                            ©OneByOne Logistical Pty Ltd 2024.
                        </div>
                        <div className="flex text-[28px] gap-6">
                            <a
                                href={endpoints.linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="icon-LinkedinIcon themes-text-hover-brand-v5 transition-all duration-300 " />
                            </a>
                            <a
                                href={endpoints.x}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="icon-XIcon themes-text-hover-brand-v5 transition-all duration-300 " />
                            </a>
                            <a
                                href={endpoints.instagram}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="icon-InstagramIcon themes-text-hover-brand-v5 transition-all duration-300 " />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainPage
